.blockquote {
    padding-left: 24px;
    position: relative;
}

.blockquote .title2 {
    color: rgba(var(--color-accent));
}

.blockquote::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 4px;
    left: 0;
    top: 0;
    background-color: rgba(var(--color-accent));
    border-radius: 4px;
}